.form-bg {
    background: #e4e6e6;
}

form {
    font-family: sans-serif;
}

.ContactForm {
    padding-top: 5px;
}

.ContactForm .header {
    background: #ff2b06;
    padding: 30px 25px;
    font-size: 30px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
}

.ContactForm .form-content {
    padding: 25px;
    background: #fff;
}

.ContactForm .form-control {
    background: #f2f4f1!important;
    padding: 12px 16px 12px 39px;
    height: 50px;
    font-size: 14px;
    color: #2b2a2a;
    border: none;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 15px;
}

.ContactForm .form-control:focus {
    border-color: #ff2b06;
    box-shadow: none;
}

.ContactForm .control-label {
    font-size: 17px;
    color: #ccc;
    position: absolute;
    top: 12px;
    left: 15px;
    text-align: center;
}

.ContactForm textarea.form-control {
    resize: vertical;
    height: 130px;
}

.ContactForm .btn {
    font-size: 18px;
    color: #4e4e4e;
    margin: 5px 0;
    border: 2px solid #ccc;
    border-radius: 0;
    padding: 10px 25px;
    transition: all 0.5s ease 0s;
}

.ContactForm .btn:hover {
    background: #fff;
    border-color: #ff2b06;
    color: #ff2b06!important;
}

.ContactForm .col-12 {
    padding-left: 0;
    padding-right: 0;
}

@media only screen and (max-width: 768px) {
    .ContactForm {
        padding: 10px;
    }
}