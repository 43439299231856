.Main {
    background: #111;
    padding-bottom: 15px;
}

.Main hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 5px solid rgb(33, 37, 41);
}

.MainTitle {
    color: white;
}