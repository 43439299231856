.NavBar>* {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.NavBar {
    z-index: 100;
    background: white;
    transition: all 0.6s ease, border 0.2s ease;
    padding: 0 !important;
}

.NavBarMenu {
    margin: auto;
    max-width: 1140px;
}

.NavBarTop {
    height: 120px;
}

.NavBarTop .LogoMenu img {
    margin: 20px;
    max-width: 240px;
    transition: all 0.6s ease, border 0.2s ease;
}

.NavBarTop .Menu li a {
    line-height: 100px;
}

.NavBarTop .NavBarBtnMenu {
    padding: 37px;
}

.NavBarFix {
    height: 85px;
}

.NavBarFix .LogoMenu img {
    margin: 12px;
    width: 180px;
    margin-top: 20px;
    transition: all 1.6s ease, border 0.2s ease;
}

.NavBarFix .Menu li a {
    line-height: 70px;
}

.NavBarFix .NavBarBtnMenu {
    padding: 24px 20px;
}

.Menu {
    padding-left: 0;
}

.Menu a {
    justify-content: center;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    text-transform: uppercase;
}

.Menu a {
    line-height: 89px;
    color: #444;
    transition: color 0.6s ease, border 0.2s ease;
}

.Menu a:hover {
    text-decoration: none;
}

.Menu a span {
    transition: color 0.6s ease, border 0.2s ease;
}

.Menu a:hover span {
    border-bottom: 6px solid #ff2b06;
}

.NavBarBtnMenu {
    justify-content: center;
    align-items: center;
}

.dropdown-menu a {
    line-height: normal;
}

@media (min-width: 990px) and (max-width: 1120px) {
    .NavBarMenu .nav-link {
        font-size: 14px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: .4rem !important;
        padding-right: .4rem !important;
    }
}