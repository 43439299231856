.Speakers .card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.Speakers .TextTitle {
    font-style: italic;
    font-size: 13px;
    text-align: center;
}

@media (max-width: 990px) {
    .card-group {
        display: flex!important;
        flex-direction: column!important;
    }
    .Speakers .card-img-top {
        height: 40vw;
    }
}