body {
  background: #f6f6f6!important;
  margin: 0;
  font-family: "Inner", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.formInputError {
  border: 1px solid #f32e31!important;
}

.pointer {
  cursor: pointer;
}