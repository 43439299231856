.LogoHtml {
    padding: 10px 0;
    line-height: 1.25;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.LogoHtmlName {
    background-image: url(./../../assets/images/ted.svg);
    background-position: 0 60%;
    background-repeat: no-repeat;
    color: transparent;
    font-size: 104%;
    font-weight: 700;
}

.LogoHtmlCity {
    font-size: 66%;
    line-height: 1.25;
    white-space: nowrap;
}

.LogoHtmlInfo {
    font-size: 31%;
    font-weight: 700;
    margin-top: -.4ex;
}

.LogoHtmlInfo span {
    color: #ff2b06;
}