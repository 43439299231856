.Loading {
    position: fixed;
    width: 100%;
    margin: 0;
    background: #ffffffd4;
    padding: 7rem;
    text-align: center;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 1000;
}

.Loading .fa-spinner {
    color: #ff2b06;
    font-size: 70px;
}