.SocialMedia {
    padding: 5px;
}

.SocialMedia svg {
    font-size: 28px;
}

.SocialMediaBtnLink {
    border-radius: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    transition: all 0.6s
}

.SocialMediaT {
    line-height: 50px;
}

.SocialMediaT svg {
    font-size: 28px!important;
}

.SocialMediaF {
    line-height: 40px;
}

.SocialMediaI {
    line-height: 50px;
}

.SocialMediaF svg {
    font-size: 20px!important;
}

.SocialMediaBtnLink:hover {
    box-shadow: 0 0 10px #444;
}