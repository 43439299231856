.SideDrawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 250px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.SideDrawer.SideDrawerOpen {
    transform: translateX(0);
}

.SideDrawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 85%;
}

.SideDrawer li {
    margin: 0.3rem 0;
}

.SideDrawer a {
    text-transform: uppercase;
    color: #666;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.6s ease, border 0.2s ease;
}

.SideDrawer a:hover, .SideDrawer a:active {
    text-decoration: none;
    color: #104c5a;
    border-bottom: 6px solid #ff2b06;
}

.SideDrawer .dropdown a#basic-nav-dropdown {
    padding-left: 0;
}

@media(min-width:768px) {
    .toolbar-toggle-button {
        display: none;
    }
    .toolbar-logo {
        margin-left: 0;
    }
}