.MyData {
    padding: 1.5rem 0 0 0;
    width: 420px;
    margin: auto;
}

.MyData .fa-whatsapp {
    font-size: 30px!important;
}

.MyData .MyDataIcon {
    font-size: 25px;
    color: #444;
}

.MyData input.form-control {
    background-color: transparent!important;
    border: 0px solid!important;
    height: 30px!important;
    width: 260px!important;
}

.MyData input.form-control:focus {
    outline: 0 none!important;
    border: 0!important;
    box-shadow: none;
}